import React from 'react';
import img1 from '../../assets/Timages/HSAboutHome.jpg'
import './TBlog.css'

const TBlog = () => {
    return (
        <div>
            <header class="headerss">
                <div class="hero text-white pt-7">
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="image-container mb-5 px-4">
                                    <img src={img1} alt="" class="img-fluid" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="text-container p-4 d-flex flex-column justify-content-center h-100 mb-5"
                                >
                                    <h1 class="display-3 fw-bold">About HS Education</h1>
                                    <p class="lead">
                                    HS Education is a renowned UK university representative helping thousands of students for over 10 years. We are accredited by the British Council and all of our counsellor and admission specialists are trained by our partner universities.
                                    </p>


                                    <div class="form-container text-center">
                                        <form>
                                            {/* <div class="my-4">
                                                <input
                                                    type="email"
                                                    class="form-control form-control-lg rounded-5"
                                                    placeholder="Email Address"
                                                />
                                            </div> */}
                                            <div class="d-grid">
                                                <button class="btn btn-primary btn-lg text-white">
                                                    Read More
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <svg
                    className="frame-decoration"
                    data-name="Layer 2"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    viewBox="0 0 1920 192.275"
                >
                    <defs>
                        <style>
                            {`.cls-1 {
            fill: #f3f6f9;
          }`}
                        </style>
                    </defs>
                    <title>frame-decoration</title>
                    <path
                        className="cls-1"
                        d="M0,158.755s63.9,52.163,179.472,50.736c121.494-1.5,185.839-49.738,305.984-49.733,109.21,0,181.491,51.733,300.537,50.233,123.941-1.562,225.214-50.126,390.43-50.374,123.821-.185,353.982,58.374,458.976,56.373,217.907-4.153,284.6-57.236,284.6-57.236V351.03H0V158.755Z"
                        transform="translate(0 -158.755)"
                    />
                </svg>
            </header>
        </div>
    );
};

export default TBlog;