import React from 'react';
import i1 from '../../assets/Timages/testimonial-1.jpg'
import i2 from '../../assets/Timages/testimonial-2.jpg'
import i3 from '../../assets/Timages/testimonial-3.jpg'


import { FaArrowCircleRight } from "react-icons/fa";
import { FaArrowCircleLeft } from "react-icons/fa";
const TClientSay = () => {
    return (
        <div>
            <section id="testimonials" class="testimonials bg-light py-6">
                <div id="testimonialCarousel" class="carousel slide testiCarou">
                    <div class="carousel-inner">

                        <div class="carousel-item active">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center text-center"
                            >
                                <img
                                    src={i2}
                                    alt=""
                                    class="rounded-circle "
                                    width='120'


                                />
                                <p class="w-50 my-4 fst-italic fs-4 mb-4">
                                    “<b>HS Education</b> is an invaluable partner, delivering credible students consistently. Their direct collaboration has earned special offers for their deserving students.”
                                </p>
                                <div class="fw-bold fs-5 mt-4">Marlene Visconte</div>
                                <div>General Manager - Scouter</div>
                            </div>
                        </div>


                        <div class="carousel-item">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center text-center"
                            >
                                <img
                                    src={i3}
                                    alt=""
                                    class="rounded-circle "
                                    width='120'

                                />
                                <p class="w-50 my-4 fst-italic fs-4 mb-4">
                                    “Our collaboration with <b>HS Education</b> has been instrumental, delivering credible students consistently. We're pleased to extend special offers to their students.”
                                </p>
                                <div class="fw-bold fs-5 mt-4">Stella Virtuoso</div>
                                <div>Design Chief - Bikegirl</div>
                            </div>
                        </div>


                        <div class="carousel-item">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center text-center"
                            >
                                <img
                                    src={i1}
                                    alt=""
                                    class="rounded-circle "
                                    width='120'

                                />
                                <p class="w-50 my-4 fst-italic fs-4 mb-4">
                                    “<b>HS Education</b> stands out as our key partner, consistently providing credible students. Their collaboration has led to exclusive offers for their students.”
                                </p>
                                <div class="fw-bold fs-5 mt-4">John Spiker</div>
                                <div>Team Leader - Vanquish</div>
                            </div>
                        </div>


                    </div>
                    <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#testimonialCarousel"
                        data-bs-slide="prev"
                    >
                        <i class="fas fa-arrow-circle-left fa-3x text-secondary"><FaArrowCircleLeft size={50} /></i>
                    </button>
                    <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#testimonialCarousel"
                        data-bs-slide="next"
                    >
                        <i class="fas fa-arrow-circle-right fa-3x text-secondary"><FaArrowCircleRight size={50} /></i>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default TClientSay;