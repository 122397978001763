import React from 'react';
import './CounterSec.css'
import CountUp from 'react-countup';
import Container from 'react-bootstrap/Container';
import { FaBuilding } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa';
import { MdCountertops } from 'react-icons/md';
import { FaUniversity } from 'react-icons/fa';
import { BiBook } from 'react-icons/bi';
import { FaUserGraduate } from 'react-icons/fa';
// import { FaClipboardCheck } from 'react-icons/fa';

const CounterSec = () => {
    return (
        <Container fluid >
            <div class="services-section">
                <div class="inner-width">
                    <div class="services-container">
                        <div class="service-box ">

                            <div class="service-title-one"><CountUp delay={3} end={10000} /></div>
                            <div class="service-title">International Students Served</div>
                        </div>
                        <div class="service-box ">
                            <div class="service-title-one"><CountUp delay={3} end={150} />+</div>
                            <div class="service-title">Global Counsellors</div>
                        </div>
                        <div class="service-box ">

                            <div class="service-title-one"><CountUp delay={3} end={100} />+</div>
                            <div class="service-title">University Partners</div>
                        </div>
                        <div class="service-box ">

                            <div class="service-title-one"><CountUp delay={3} end={99} />%</div>
                            <div class="service-title">Student Satisfaction</div>
                        </div>

                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CounterSec;
