import React from 'react';
import './TAboutUs.css'
import upperImg from '../../assets/Timages/HSaboutUs.jpg'
const TAboutUs = () => {
    return (
        <div>
            {/* big Image */}
            <section>
                <div class="container">
                    <img src={upperImg} alt="upperImg" class="w-100 my-6" />
                </div>
            </section>
            {/* text section */}
            <section class="pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <p>
                            Welcome to <b>HS Education</b>, where academic aspirations transform into realities. We are dedicated to providing unparalleled educational support, ensuring students thrive in the intricate world of academia. Our pillars of service encompass
                            </p>

                            <p class="mb-4">
                            At <b>HS Education</b>, we meticulously navigate students through the educational maze, tailoring personalized roadmaps for success. From course selection to career development, we empower students to make informed choices pivotal to their academic journeys.
                            </p>

                            <h2 class="mb-3">Discover a journey of educational excellence with <b>HS Education</b></h2>

                            <p>
                            Going beyond academics, we provide comprehensive career counseling, aligning students' abilities and aspirations with suitable career paths. Through workshops, aptitude tests, and personalized sessions, we empower students to make informed career decisions, ensuring a seamless transition from education to a fulfilling career.
                            </p>

                            <p class="mb-4">
                            Recognizing the financial challenges students face, we excel in identifying scholarships and financial aid opportunities. Our consultants expertly guide students through applications, easing the financial burden and making quality education accessible.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* cards */}
            <section class="my-4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5>
                                        <i class="fas fa-check text-secondary"></i> Career Planning and Guidance
                                    </h5>
                                    <p>
                                    Offering comprehensive career planning services that involve assessing students' skills, interests, and career aspirations. Providing guidance on suitable career paths, educational requirements, and strategies for achieving career goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card my-3">
                                <div class="card-body">
                                    <h5>
                                        <i class="fas fa-check text-secondary"></i> Study Abroad Programs and Assistance
                                    </h5>
                                    <p>
                                    Assisting students in exploring and applying for study programs abroad, including undergraduate and postgraduate degrees. Providing guidance on selecting the right universities, countries, and programs that align with their academic and career objectives.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5>
                                        <i class="fas fa-check text-secondary"></i> Skill Development Workshops
                                    </h5>
                                    <p>
                                    Conducting workshops and training sessions to enhance students' soft skills, such as communication, time management, leadership, and problem-solving. Focusing on building a well-rounded skill set that is essential for academic success and future employment opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* gray box */}
            <section class="my-5">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card bg-light rounded-0 border-0 fs-6">
                            <div class="card-body p-4">
                                <h3>We know which university is the best fit for the student.</h3>
                                <p>
                                Understanding the unique needs and aspirations of each student, we possess the expertise to accurately identify the ideal university for them. Through careful evaluation of academic strengths, personal interests, and future goals, we can confidently recommend the university that aligns best with their educational journey. Our in-depth knowledge of various universities allows us to match students with institutions that offer the right programs, resources, and environment for a successful and fulfilling academic experience. With this insight, we guide students towards making informed decisions, ensuring a seamless transition into higher education.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};

export default TAboutUs;