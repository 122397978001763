import React from 'react';

const TCommonReg = () => {
    return (
        <div>
            <section class="invitation mb-5 bg-light " >
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="invitation-bg text-center py-6 rounded-5 ">
                                <div class="text-white w-75 m-auto pt-5 pb-5">
                                    <h2 class="display-6 fw-bold pt-5">Admission is Open for the Upcoming Intake</h2>
                                    {/* <p>
                                        We cordially invite you to attend a seminar on December 22nd,
                                        where we will explore various topics and insights related to
                                        <strong>advertising and marketing</strong>. It will be an
                                        engaging session where you can gain valuable knowledge about.
                                        Don't miss out on this opportunity to enhance your skills and
                                        broaden your horizons. Join us and be a part of this enriching
                                        experience!
                                    </p> */}
                                    <a href="#register" class="btn btn-primary btn-lg">
                                        Register Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TCommonReg;