import React from 'react';
import img1 from '../../assets/Timages/hsService.jpg'

import { FaCentos } from "react-icons/fa";
const TServices = () => {
    return (

        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-container d-flex flex-column justify-content-center h-100">
                        <h2 class="display-6">Services for Students</h2>
                        <p>
                        We are committed to helping our students to choose the right course and university for their higher education.
                        </p>
                        <ul class="list-group list-group-flush lh-lg">
                            {/* <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                <strong>Unleash Your Creativity:</strong> Our ebook empowers
                                you to unleash your creativity and express your unique voice
                                through your blog.
                            </li> */}
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                Initial assessment and course selection guidance
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                University admission & application guidance
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                Scholarships assessment and application support
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                Student visa application guidance
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                Mock visa interview by our compliance officer
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                On-demand accommodation guidance
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-square text-primary"><FaCentos size={20}/></i>
                                Plane ticket and pre-arrival training
                            </li>

                        </ul>
                        <a class="btn btn-primary text-white mt-4 align-self-start" data-bs-toggle="modal" data-bs-target="#modal1" >Register Now</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="image-container p-5">
                        <img src={img1}alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TServices;