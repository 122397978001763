import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';

import TFooter from '../TFooter/TFooter';

const Universities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='mt-2'>
            <HeaderNav></HeaderNav>
            <section id="hero">
                <div class="container-fluid">
                    <div class="jumbotron" style={{marginTop:'81px'}}>
                        <h1 class="text-center pt-5 pb-5">| Our Universities |</h1>

                    </div>
                </div>
            </section>


 
            <div className="mt-5 mb-5">
                <h1 className='text-center mt-5 mb-5'>We have 500+ Universities all over the World <span style={{fontSize:'10px'}}>T&C apply</span> </h1>
            </div>


            <TFooter/>
        </div>
    );
};

export default Universities;



/*








import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
import BlogBg from '../../assets/blogBg.jpg'

import i1 from '../../assets/Uni Logo update/1.png'
import i2 from '../../assets/Uni Logo update/2.jpg'
import i3 from '../../assets/Uni Logo update/3.jpg'
import i4 from '../../assets/Uni Logo update/4.png'
import i5 from '../../assets/Uni Logo update/5.png'
import i6 from '../../assets/Uni Logo update/6.jpg'
import i7 from '../../assets/Uni Logo update/7.jpg'
import i8 from '../../assets/Uni Logo update/8.png'
import i9 from '../../assets/Uni Logo update/9.jpg'
import i10 from '../../assets/Uni Logo update/10.jpg'
import i11 from '../../assets/Uni Logo update/11.jpg'
// import i12 from '../../assets/Uni Logo update/12.png'
import i13 from '../../assets/Uni Logo update/13.jpg'
import i14 from '../../assets/Uni Logo update/14.png'
import i15 from '../../assets/Uni Logo update/15.png'
import i16 from '../../assets/Uni Logo update/16.png'
// import i17 from '../../assets/Uni Logo update/17.png'
import i18 from '../../assets/Uni Logo update/18.png'
import i19 from '../../assets/Uni Logo update/19u.png'
import i20 from '../../assets/Uni Logo update/20.jpg'
import i21 from '../../assets/Uni Logo update/21.png'
import i22 from '../../assets/Uni Logo update/22.png'
import i23 from '../../assets/Uni Logo update/23.png'
import i24 from '../../assets/Uni Logo update/24.jpg'
import i25 from '../../assets/Uni Logo update/25.jpg'
import i26 from '../../assets/Uni Logo update/26.png'
import i27 from '../../assets/Uni Logo update/27.jpg'
import i28 from '../../assets/Uni Logo update/28.jpg'
import i29 from '../../assets/Uni Logo update/29.jpg'
import i30 from '../../assets/Uni Logo update/30.jpg'
import i31 from '../../assets/Uni Logo update/31.png'
import i32 from '../../assets/Uni Logo update/32.jpg'
// import i33 from '../../assets/Uni Logo update/33.png'
import i34 from '../../assets/Uni Logo update/34.png'
import i35 from '../../assets/Uni Logo update/35.jpg'
import i36 from '../../assets/Uni Logo update/36.jpg'
import i37 from '../../assets/Uni Logo update/37.png'
import i38 from '../../assets/Uni Logo update/38.png'
import i39 from '../../assets/Uni Logo update/39.jpg'
import i40 from '../../assets/Uni Logo update/40.jpg'
import i41 from '../../assets/Uni Logo update/41.png'
import i42 from '../../assets/Uni Logo update/42.jpg'
import i43 from '../../assets/Uni Logo update/43.png'
import i44 from '../../assets/Uni Logo update/44.png'
import i45 from '../../assets/Uni Logo update/45.jpg'
import i46 from '../../assets/Uni Logo update/46.png'
import i47 from '../../assets/Uni Logo update/47.jpg'
import i48 from '../../assets/Uni Logo update/25.png'
import i49 from '../../assets/Uni Logo update/48.jpg'
import i50 from '../../assets/Uni Logo update/49.png'
import s1 from '../../assets/Uni Logo update/studyGroup/s1.svg'
import s2 from '../../assets/Uni Logo update/studyGroup/s2.webp'
import s3 from '../../assets/Uni Logo update/studyGroup/s3.webp'
import s4 from '../../assets/Uni Logo update/studyGroup/s4.svg'
import s5 from '../../assets/Uni Logo update/studyGroup/s5.svg'
import s6 from '../../assets/Uni Logo update/studyGroup/s6.svg'
import s7 from '../../assets/Uni Logo update/studyGroup/s7.svg'
import s8 from '../../assets/Uni Logo update/studyGroup/s8.svg'
import s9 from '../../assets/Uni Logo update/studyGroup/9.svg'
import s10 from '../../assets/Uni Logo update/studyGroup/10.svg'
import s11 from '../../assets/Uni Logo update/studyGroup/11.svg'
import s12 from '../../assets/Uni Logo update/studyGroup/12.svg'
import s13 from '../../assets/Uni Logo update/studyGroup/13.svg'
import s14 from '../../assets/Uni Logo update/studyGroup/14.svg'
import s15 from '../../assets/Uni Logo update/studyGroup/15.svg'
import s16 from '../../assets/Uni Logo update/studyGroup/16.svg'
import s17 from '../../assets/Uni Logo update/studyGroup/17.webp'
import s18 from '../../assets/Uni Logo update/studyGroup/18.svg'
import s19 from '../../assets/Uni Logo update/studyGroup/19.svg'
import s20 from '../../assets/Uni Logo update/studyGroup/20.svg'
import s21 from '../../assets/Uni Logo update/studyGroup/21.webp'
import s22 from '../../assets/Uni Logo update/studyGroup/22.svg'
import s23 from '../../assets/Uni Logo update/studyGroup/23.svg'
import s24 from '../../assets/Uni Logo update/studyGroup/24.svg'
import s25 from '../../assets/Uni Logo update/studyGroup/25.svg'
import s26 from '../../assets/Uni Logo update/studyGroup/26.svg'
import s27 from '../../assets/Uni Logo update/studyGroup/27.svg'
import s28 from '../../assets/Uni Logo update/studyGroup/28.svg'
import s29 from '../../assets/Uni Logo update/studyGroup/29.svg'
import s30 from '../../assets/Uni Logo update/studyGroup/30.svg'
// navitas
import n1 from '../../assets/Uni Logo update/navitas/1.jpg'
import n2 from '../../assets/Uni Logo update/navitas/2.jpg'
import n3 from '../../assets/Uni Logo update/navitas/3.jpg'
import n4 from '../../assets/Uni Logo update/navitas/4.jpg'
import n5 from '../../assets/Uni Logo update/navitas/5.jpg'

import { Accordion, Carousel } from 'react-bootstrap';

const Universities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='mt-2'>
            <HeaderNav></HeaderNav>
            <div class="mt-5 container-fluid bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${BlogBg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Universities <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>
            <div class="grey-bg container">
                <section id="minimal-statistics">
                    <div class="row">
                        <div class="col-12 mt-3 mb-1">
                            <h4 class="text-uppercase">Our Universities</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i1} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i2} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i3} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i4} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i5} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i6} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i7} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i8} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i9} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i10} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i11} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i49} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i13} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i14} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i15} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i16} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i18} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i19} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i20} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i21} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i22} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i23} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i24} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i25} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i26} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i50} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i27} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i28} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i29} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i30} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i31} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i32} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i34} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i35} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i36} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i37} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i38} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i39} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i48} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i40} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i41} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i42} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i43} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i44} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i45} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i46} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i47} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>coming soon</p>
                                            <a href="" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='container text-center p-3'>
                <h1>SGE Has Access To Most Of The <b>United States of America</b>, <b>New Zealand</b>, <b>Australia</b> And <b>Canada</b> Universities</h1>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i44} class="img-fluid" alt="Responsive Image" />
                        <p>Study Group is a for-profit education provider that prepares international students for university degree programmes and offers English language courses. The company operates in the United Kingdom.</p>
                    </div>
                </div>
            </div>
            <h1 className='text-center'>United States of America</h1>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s22} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s23} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s24} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s25} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s26} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s27} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s28} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s29} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s30} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Australia</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s1} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Ireland</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s2} class="img-fluid" alt="Responsive Image" />
                    </div>

                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Netherlands</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s3} class="img-fluid" alt="Responsive Image" />
                    </div>

                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>New Zealand</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s4} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s5} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>
            <div class="container">
                <h1 className='text-center'>United Kingdom</h1>
                <div class="row justify-content-center">
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s6} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s7} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s8} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s9} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s10} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s11} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s12} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s13} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s14} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s15} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s16} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s17} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s18} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s19} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s20} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s21} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i45} class="img-fluid" alt="Responsive Image" />
                        <p>Give your students the opportunity to study at esteemed universities and colleges in countries across the globe with Navitas: the UK, the USA, Canada, Australia, the Netherlands, Germany, Singapore, New Zealand, UAE, and Sri Lanka. With a range of flexible entry points, diverse campuses, and hundreds of program options to choose from, Navitas caters to the needs of every student.</p>
                    </div>
                </div>
            </div>
            <div className='container testHeight '>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n2}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n3}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n4}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n5}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i46} class="img-fluid" alt="Responsive Image" />
                        <p>Every year we help thousands of international students from around the world achieve their dream of studying in the UK, US and China. We work with universities to create specialist programs designed specifically for international students.</p>
                    </div>
                </div>
            </div>
            <h1 className='text-center'>University list coming soon </h1>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i47} class="img-fluid" alt="Responsive Image" />
                        <p>We believe that everything starts with our students. Transforming their careers and their lives is what motivates and inspires us.</p>
                    </div>
                </div>
            </div>
            <h1 className='text-center'>University list coming soon </h1>
            <Footer></Footer>
        </div>
    );
};

export default Universities;




















*/