import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Map from '../../Shared/Map/Map';
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
import FormContactUs from '../../Shared/Form/FormContactUs/FormContactUs';
import TFooter from '../TFooter/TFooter';
import TContactUs from '../TContactUs/TContactUs';
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>

            <HeaderNav></HeaderNav>
            <section id="hero">
                <div class="container-fluid">
                    <div class="jumbotron" style={{ marginTop: '81px' }}>
                        <h1 class="text-center pt-5 pb-5">| Contact Us |</h1>

                    </div>
                </div>
            </section>


            <TContactUs />

            {/* <Map></Map> */}
            {/* <FormContactUs /> */}



            <TFooter />
        </div>
    );
};

export default ContactUs;

/*

            <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
                <iframe
                    src="https://app.shabujglobal.com/registration/student"
                    style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                >
                    Loading…
                </iframe>
            </div>


*/