import React from 'react';
import './WhyBest.css'
import img1 from '../../assets/why/why1.png'
import img2 from '../../assets/why/why2.png'
import img3 from '../../assets/why/why3.png'
import img4 from '../../assets/why/why4.png'


import { FaUniversity } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";

const WhyBest = () => {
    return (
        <div className=' whyBest mb-5' >
            <div class="main text-center mt-5">
                <h6 class="heading">Why We Are The Best</h6>
                <p class="container p-5 pt-0 pb-0"><b>Our service is tailored for each student based on their personal circumstances and their future goals.</b></p>
            </div>
            <div class="container mt-4 d-flex justify-content-center ">
                <div class="row g-0">
                    <div class="col-md-3 border-right">
                        <div class="cards">
                            <div class="first  p-4 text-center">
                                <div class="iconColor"><FaUniversity size={65} /></div>

                                <h5>Recognized by Top Universities</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 border-right">
                        <div class="cards">
                            <div class=" second  p-4 text-center">
                                <div class="iconColor"><FaUsers size={65} /></div>

                                <h5>Experienced Team</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="cards">
                            <div class=" third  p-4 text-center">
                                <div class="iconColor"><FaGraduationCap size={65} /></div>
                                <h5>Scholarships Opportunities</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="cards">
                            <div class=" third  p-4 text-center">
                                <div class="iconColor"><FaBookOpen size={65} /></div>
                                <h5>Wide Range of Course Options</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyBest;