import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CorporateSR from '../components/CorporateSR/CorporateSR';
import CounterSec from '../components/CounterSec/CounterSec';
import EventCarousal from '../components/EventCarousal/EventCarousal';
import FacebookReview from '../components/FacebookReview/FacebookReview';
import Footer from '../components/Footer/Footer';
import Gallery from '../components/Gallery/Gallery';
import HeaderNav from '../components/HeaderNav/HeaderNav';
import OfficeLocation from '../components/OfficeLocation/OfficeLocation';
import UniLogoSlider from '../components/UniLogoSlider/UniLogoSlider';
import VideoHeader from '../components/VideoHeader/VideoHeader';
import NewsPopup from '../Shared/NewsPopup/NewsPopup';
import TBlog from '../components/TBlog/TBlog';
import TSectionIcon from '../components/TSectionIcon/TSectionIcon';
import TServices from '../components/TServices/TServices';
import TEvents from '../components/TEvents/TEvents';
import TCommonReg from '../components/TCommonReg/TCommonReg';
import TClientSay from '../components/TClientSay/TClientSay';
import TServiceTwo from '../components/TServiceTwo/TServiceTwo';
import TFooter from '../components/TFooter/TFooter';
const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderNav></HeaderNav>
      <VideoHeader></VideoHeader>
      {/* traversy test */}
      <TBlog/>
      <TSectionIcon/>
      <TEvents/>
      <TServices/>
      <TCommonReg/>
      <TClientSay/>
      {/* <TServiceTwo/> */}
      {/* traversy test END */}

      {/* <NewsPopup/> */}
      {/* <EventCarousal></EventCarousal> */}
      {/* <CounterSec></CounterSec> */}
      {/* <Gallery></Gallery> */}
      {/* <UniLogoSlider></UniLogoSlider> */}
      {/* <OfficeLocation></OfficeLocation> */}

      {/* <div className='container-fluid myServiceBg'>
        <Link to='ouroffice'><h1 className='text-center text-white'>Discover All Our Office Locations</h1></Link>
      </div> */}

      {/* <FacebookReview></FacebookReview> */}
      {/* <CorporateSR></CorporateSR> */}
      {/* <Footer></Footer> */}
      <TFooter/>
    </div>
  );
};

export default Main;