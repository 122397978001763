import React from 'react';

import { FaUser } from 'react-icons/fa';
import { FaRocket } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";


const TSectionIcon = () => {
    return (
        <div>
            <section class="icons bg-light pb-5">
                <div class="container-xl">
                    <div class="row hstack g-4">
                        <div class="col-md-3 d-flex gap-4">
                            <i class="fas fa-user fa-3x text-primary"><FaUser size={50} /></i>
                            <div>
                                <h5 class="fw-bold">Academic Guidance</h5>
                                <p class="text-muted">
                                We offer personalized guidance, helping students navigate education, tailor academic paths, and make informed choices vital for success.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3 d-flex gap-4">
                            <i class="text-secondary"><FaRocket size={50} /></i>
                            <div>
                                <h5 class="fw-bold">Admissions Support</h5>
                                <p class="text-muted">
                                We specialize in admissions, aiding applications, exams, and interviews, boosting admission prospects globally.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3 d-flex gap-4">
                            <i class="fas fa-dollar fa-3x text-primary"><FaDollarSign size={50} /></i>
                            <div>
                                <h5 class="fw-bold">Financial Aid Assistance</h5>
                                <p class="text-muted">
                                Expertly finding scholarships, easing students' financial burden, securing financial support for their education.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3 d-flex gap-4">
                            <i class="text-secondary"><FaRocket size={50} /></i>
                            <div>
                                <h5 class="fw-bold">Expert Career Counseling</h5>
                                <p class="text-muted">
                                Comprehensive career guidance for a smooth transition to fulfilling careers, evaluating abilities and aligning aspirations.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default TSectionIcon;