import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../assets/shabujLogo.png'
import logo1 from '../../assets/hs-education-logo.png'
import { Link } from "react-router-dom";
import './HeaderNav.css'

function HeaderNav() {
    return (
        <div className="">
            <Navbar style={{ backgroundColor: '#000000a1' }} expand="lg" fixed="top" >  {/*bg='dark' */}
                <Container className='p-0'>
                    <Navbar.Brand>
                        <Link to='/'>
                        <img
                            src={logo1}
                            width="309"
                            height="55"
                            className="navImg d-inline-block align-top"
                            alt="SGE LOGO"
                        /></Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
                        <Nav className="">
                            <Link className='myNav' to='/'>Home </Link>
                            <Link className='myNav' to='/about'>About Us </Link>
                            <Link className='myNav' to='/services'>Our Services </Link>
                            <Link className='myNav' to='/events'>Events </Link>
                            {/* <Link className='myNav' to='/agents'>Agent </Link> */}
                            {/* <Link className='myNav' to='/registration'>Registration </Link> */}
                            <Link className='myNav' to='/Universities'>Universities </Link>
                            <Link className='myNav' to='/contact'>Contact Us </Link>
                            {/* <Link className='myNav' to='/blogs'>Blog</Link> */}
                            {/* <a className='myNav' target='_blank' href="https://shabujglobal.uuacrm.com/" rel="noreferrer">Login</a> */}
                            <button className="custom-btn btn-11">Register</button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>

    );
}
export default HeaderNav;
