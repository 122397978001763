import React from 'react';
import './TFooter.css'
import hsLogo from '../../assets/hs-education-logo.png'
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { BiLocationPlus } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";






const TFooter = () => {
    return (
        <div>
            <div class="footer">
            <div class="container">     
                <div class="row">                       
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="single_footer">
                            <img src={hsLogo} className='w-75' alt="" />
                            <p className='footerLogoPara'>HS Education is a renowned UK university representative helping thousands of students for over 10 years.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="single_footer">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><a href="#">Career</a></li>
                                <li><a href="#">NHS</a></li>
                                <li><a href="#">UKVI</a></li>
                                <li><a href="#">HS Language IELTS</a></li>
                                <li><a href="#">Webmail</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="single_footer single_footer_address">
                            <h4>Contact Us</h4>
                            <ul>
                                {/* <li><p><FaPhoneAlt/> +44 020 7790 4894</p></li> */}
                                <li><p><AiOutlineMail/> admissions@hsedu.co.uk</p></li>
                                {/* <li><p><BiLocationPlus/> 1st Katerine,s way , London E1W 1UN, UK </p></li> */}
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="social_profile">
                            <ul>
                                <li><a href="#"><FaFacebook/></a></li>
                                <li><a href="#"><FaTwitter/></a></li>
                                <li><a href="#"><FaInstagram/></a></li>
                                <li><a href="#"><FaYoutube/></a></li>
                            </ul>
                        </div>                          
                    </div>       
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <p class="copyright">Copyright © 2023 <a href="#">HS Education</a>.</p>
                    </div>               
                </div>                
            </div>
        </div>
        </div>
    );
};

export default TFooter;