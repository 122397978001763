import React, { useEffect } from 'react';
import './OurServices.css'
import { FaBuilding } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa';
import { MdCountertops } from 'react-icons/md';
import { FaUniversity } from 'react-icons/fa';
import { BiBook } from 'react-icons/bi';
import { FaUserGraduate } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import CountUp from 'react-countup';
import Service from '../Service/Service';
import HeaderNav from '../HeaderNav/HeaderNav';
import Footer from '../Footer/Footer';
import bg from '../../assets/blogBg.jpg'
import TFooter from '../TFooter/TFooter';
const OurServices = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
    return (
        <Container fluid >
            <HeaderNav></HeaderNav>
            <section id="hero">
                <div class="container-fluid">
                    <div class="jumbotron" style={{marginTop:'81px'}}>
                        <h1 class="text-center pt-5 pb-5">| Our Services |</h1>

                    </div>
                </div>
            </section>
            <Service></Service>
            <TFooter/>
        </Container>
    );
};

export default OurServices;