import React from 'react';
import './TContactUs.css'
const TContactUs = () => {
    return (
        <div>



            <header class="header">

                <div class="hero text-white pt-7 pb-4">
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-12">
                                {/* <h1>Contact Information</h1> */}
                                <h1 className='pt-5'>
                                    Contact or visit HS Education for assistance and information about our services. </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <svg
                    className="frame-decoration"
                    data-name="Layer 2"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    viewBox="0 0 1920 192.275"
                >
                    <defs>
                        <style>
                            {`.cls-1 {
            fill: #f3f6f9;
          }`}
                        </style>
                    </defs>
                    <title>frame-decoration</title>
                    <path
                        className="cls-1"
                        d="M0,158.755s63.9,52.163,179.472,50.736c121.494-1.5,185.839-49.738,305.984-49.733,109.21,0,181.491,51.733,300.537,50.233,123.941-1.562,225.214-50.126,390.43-50.374,123.821-.185,353.982,58.374,458.976,56.373,217.907-4.153,284.6-57.236,284.6-57.236V351.03H0V158.755Z"
                        transform="translate(0 -158.755)"
                    />
                </svg>
            </header>


            <section class="contact bg-light pb-4">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <form action="https://formspree.io/f/mrgvnzvq" method="POST">
                                <div class="mb-3">
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        name="name"
                                        placeholder="Name"
                                    />
                                </div>
                                <div class="mb-3">
                                    <input
                                        type="email"
                                        class="form-control form-control-lg"
                                        name="email"
                                        placeholder="Email"
                                    />
                                </div>
                                <div class="mb-3">
                                    <textarea
                                        class="form-control form-control-lg"
                                        name="message"
                                        rows="6"
                                        placeholder="Message"
                                    ></textarea>
                                </div>
                                <div class="d-grid">
                                    <button type="submit" class="btn btn-primary text-white mt-3">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            <section class="location my-5">
                <div class="container">
                    <div class="row">
                        <div class="col-10 offset-1">
                            <h3>Our Location</h3>
                            <p class="mb-4">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid
                                deserunt recusandae iure officiis quia saepe at velit ad eum
                                tenetur magnam, nihil voluptate sit totam ut reiciendis iusto
                                harum doloribus.
                            </p>
                            <div class="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1241.5303553091994!2d-0.14076024298621118!3d51.51210217963597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d502268421%3A0x6a7d62889992f993!2sRegent+St%2C+Soho%2C+London+W1B+5TH%2C+UK!5e0!3m2!1sen!2sro!4v1476174541049"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            {/* <div class="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.197992016665!2d-0.0657018842892312!3d51.50652057963416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761c66a9e4fe65%3A0x6947f0e1518fbf4a!2s1st%20Katherine&#39;s%20Way%2C%20London%20E1W%201UN%2C%20UK!5e0!3m2!1sen!2sro!4v1565053098699&q=1st%20Katherine&#39;s%20Way%2C%20London%20E1W%201UN%2C%20UK" allowfullscreen></iframe>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>











        </div>
    );
};

export default TContactUs;