import React from 'react';
import './VideoHeader.css'
import Typewriter from 'typewriter-effect';

import vidBg2 from '../../assets/vidBg.mp4'
import { ImFacebook } from 'react-icons/im';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';

const VideoHeader = () => {
    return (
        <div>
            <section class="showcase">
                <video src={vidBg2} muted loop autoPlay />
                <div class="overlay"></div>
                <div class="text">
                    {/* <h1><Typewriter
                        options={{
                            strings: [
                                'Embark on your journey to global education today!',
                                'Begin your exciting voyage towards global education.',
                                'Take the first step towards a world-class education experience.',
                                'Start your transformative path to global education.'

                            ],
                            autoStart: true,
                            loop: true,
                            deleteAll:10000
                        }}
                    /></h1> */}

                    <h1>Start Your </h1>
                    <h4><Typewriter
                        options={{
                            strings: [
                                'quest for a global education experience...',
                                'path to unlock global education opportunities...',
                                'path towards global learning...',
                                ' adventure towards a global education...'

                            ],
                            autoStart: true,
                            loop: true,
                            deleteAll: 10000,
                            speed: 1000
                        }}
                    /></h4>
                    {/* <p>We take pride in our ability to help students achieve their academic goals and succeed in life.</p> */}


                    {/* <a href="https://app.shabujglobal.com/register/" target='_blank' rel="noreferrer">
                        <button className='glow-on-hover vidHeaderBtn'>Apply Now</button>
                    </a> */}

                    <button className="custom-btn btn-11">Register</button>

                </div>
                {/* <ul class="social">
                    <li><a   href="https://www.facebook.com/ShabujGlobaleducationuk/" target="_blank" rel="noreferrer"><ImFacebook style={{fontSize:'46px',color:'#261919'}} /></a></li>
                    <li><a   href="https://twitter.com/shabujglobaluk" target="_blank" rel="noreferrer"><AiOutlineTwitter style={{fontSize:'46px',color:'#261919'}} /></a></li>
                    <li><a   href="https://www.linkedin.com/company/shabuj-global-education/" target="_blank" rel="noreferrer"><AiOutlineLinkedin style={{fontSize:'46px',color:'#261919'}} /></a></li>
                    <li><a   href="https://youtube.com/@shabujglobaleducation1568" target="_blank" rel="noreferrer"><AiFillYoutube style={{fontSize:'46px',color:'#261919'}} /></a></li>
                    <li><a   href="https://instagram.com/shabujglobaleducation" target="_blank" rel="noreferrer"><AiOutlineInstagram style={{fontSize:'46px',color:'#261919'}} /></a></li>
                </ul> */}
            </section>
        </div>
    );
};

export default VideoHeader;