import React, { useEffect } from 'react';
import BlogBg from '../../assets/blogBg.jpg'
import img1 from '../../assets/events/event4.jpg'
import img2 from '../../assets/events/event5.jpg'
import img3 from '../../assets/events/event6.jpg'
import img4 from '../../assets/events/event7.jpg'
import img5 from '../../assets/events/event8.jpg'
import img6 from '../../assets/events/event9.jpg'
// import img7 from '../../assets/events/event10.jpg'
import img8 from '../../assets/events/event11.jpg'
// import img7 from '../../assets/events/event10.png'
import CountdownTimerOne from '../../Shared/CountdownTimer/CountdownTimerOne/CountdownTimerOne';
import CountdownTimerTwo from '../../Shared/CountdownTimer/CountdownTimerTwo/CountdownTimerTwo';
import CountdownTimerThree from '../../Shared/CountdownTimer/CountdownTimerThree/CountdownTimerThree';

const EventsSg = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='mt-5'>


            <section id="hero">
                <div class="container-fluid">
                    <div class="jumbotron" style={{ marginTop: '81px' }}>
                        <h1 class="text-center pt-5 pb-5">| Events & Spot Admission |</h1>

                    </div>
                </div>
            </section>


            <div className='text-center pb-3 mt-5 mb-5'>
                <h1 className='mt-5 mb-5'>Events and Spot Admissions sessions are coming soon. </h1>
            </div>

{/* SGE events card */}

            {/* <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <div className="text-center"><iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F3467476763481988%2F&show_text=false&width=269&t=0" width="269" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Are you ready to take the first step towards an incredible future?</h6>
                                    <p class="card-text text-justify">we've got exciting news for you! <b>Liam Stott</b>, the International Recruitment Manager of the prestigious <b>University of Bedfordshire</b>, is coming to Dhaka on <b>May 27th </b>at Shabuj Global Dhaka Office to conduct an <b>on-spot assessment</b> session for the upcoming intake on November 2023 & January 2024. We are thrilled to have Liam Stott join us for this incredible opportunity.</p>
                                    <p>Learn more about the <b>University of Bedfordshire</b> and receive expert advice from <b>Liam Scotts</b> on how to make your application stand out. With his years of experience in international student recruitment, <b>Liam Scotts</b> is the perfect person to guide you through the process and help you achieve your goals.</p>
                                    <p>Location- <b>SG Dhaka Office</b> Nagar Lutfun Homes, House 11, Rd No 17, Block D, Banani, Dhaka 1213</p>
                                    <p><b>Date: 27th May, 2023</b></p>

                                    <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <CountdownTimerOne />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className='container-fluid myServiceBg'>
                <h1 className='text-center text-white'>Previous Events</h1>
            </div> */}

            {/* <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <div className="text-center"><iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F1431696250913349%2F&show_text=false&width=476&t=0" width="300" height="325" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Dear Students,</h6>
                                    <p class="card-text text-justify">Come to <b>Shabuj Global Education's</b> spectacular Higher Education Fair with us! Meet representatives from prestigious colleges and universities from countries such as the <b>USA, Canada, UK, Australia, Malta</b>, and more!</p>
                                    <p>Location- <b>Hotel Noorjahan Grand</b>, Sylhet</p>
                                    <p><b>Date: 2nd May, 2023</b></p>
                                    <p>Don't pass up this wonderful opportunity—<b>entry is free!</b> We're eager to meet you there!</p>
                                    <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled >APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

{/* SGE events card END */}


        </div>
    );
};

export default EventsSg;