import React from 'react';
import { Card, CardGroup, Nav } from 'react-bootstrap';
import './Service.css'

import img1 from '../../assets/ourService/1.jpg'
import img2 from '../../assets/ourService/2.jpg'
import img3 from '../../assets/ourService/3.jpg'
import Testimonial from '../../Shared/Testimonial/Testimonial';
import TServiceTwo from '../TServiceTwo/TServiceTwo';

const Service = () => {
    return (
        <div>
          <TServiceTwo/>
        </div>
    );
};

export default Service;