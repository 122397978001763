import React from 'react';
import i1 from '../../assets/Timages/yavin/details-1.png'
import i3 from '../../assets/Timages/yavin/details-2.png'
import i2 from '../../assets/Timages/yavin/decoration-star.svg'
import './TServiceTwo.css'
import { FaCheck } from "react-icons/fa";
import TCommonReg from '../TCommonReg/TCommonReg';

const TServiceTwo = () => {
    return (
        <div>
            {/* Service ONE */}
            <section
                id="details"
                class="details position-relative my-6 overflow-hidden"
            >
                <img
                    src={i2}
                    alt=""
                    class="decoration-star position-absolute"
                />
                <div class="container position-relative z-3">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-container d-flex justify-content-center">
                                <img src={i1} alt="" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mt-4">
                                <h2 class="mb-4">
                                    Service <span class="text-primary">For</span> Institutions
                                </h2>
                                <ul class="list-unstyled">
                                    <li class="d-flex mb-3">
                                        <i class="fas fa-check text-primary fa-2x mx-4"><FaCheck size={20} /></i>
                                        <p>
                                        Student Recruitment and Enrollment Strategies
                                        </p>
                                    </li>
                                    <li class="d-flex mb-3">
                                        <i class="fas fa-check text-primary fa-2x mx-4"><FaCheck size={20} /></i>
                                        <p>
                                        Market Research and Analysis
                                        </p>
                                    </li>
                                    <li class="d-flex mb-3">
                                        <i class="fas fa-check text-primary fa-2x mx-4"><FaCheck size={20} /></i>
                                        <p>
                                        Partnership Development and Collaboration
                                        </p>
                                    </li>
                                    <li class="d-flex mb-3">
                                        <i class="fas fa-check text-primary fa-2x mx-4"><FaCheck size={20} /></i>
                                        <p>
                                        Marketing and Branding Support
                                        </p>
                                    </li>
                                    <li class="d-flex mb-3">
                                        <i class="fas fa-check text-primary fa-2x mx-4"><FaCheck size={20} /></i>
                                        <p>
                                        Admissions Process Enhancement
                                        </p>
                                    </li>
                                </ul>
                                <a href="article.html" class="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TCommonReg />
            {/* Service Two */}

            <section class="details-2 position-relative my-6 overflow-hidden">
                <img
                    src={i2}
                    alt=""
                    class="decoration-star position-absolute"
                />
                <div class="container position-relative z-3">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="text-container text-center text-lg-start mb-4">
                                <h2>
                                    Transform Your Career with
                                    <span class="text-primary"> HS Education</span>
                                </h2>
                                <p>
                                    Unlock new opportunities and propel your professional growth with HS Education.
                                    Take your career to the next level with HS Education's transformative programs.
                                    Invest in your future and achieve career success with HS Education.
                                    Elevate your career prospects and gain valuable skills with HS Education.
                                    Empower yourself and make a lasting impact on your career through HS Education.
                                    Discover a world of possibilities and enhance your career trajectory with HS Education.
                                    Gain the knowledge and expertise to excel in your career through HS Education.
                                    Unleash your potential and shape a rewarding career path with HS Education.
                                    Embrace the transformative power of education and shape your career journey with HS Education.
                                </p>
                                <a href="article.html" class="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="image-container d-flex justify-content-center">
                                <img src={i3} alt="" class="iimg-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>
    );
};

export default TServiceTwo;