import React from 'react';
import './TEvents.css'
import i1 from '../../assets/Timages/corso/header-slide-1.jpg'
import i2 from '../../assets/Timages/corso/header-slide-2.jpg'
import i3 from '../../assets/Timages/corso/header-slide-3.jpg'

const TEvents = () => {
    return (
        <div>
            <header class="headeree py-7 vh-100">
                <div class="container">
                    <div class="row mb-5 text-center">
                        <div class="col-12 text-container">
                            <h1 class="display-2 text-white mt-3">
                            Events For Our Students
                            </h1>
                            <p class="lead text-white w-75 m-auto mb-4">
                            Stay connected for upcoming university admission events! Gain valuable insights, guidance, and connect with representatives and students. Explore admission process, programs, campus life, and career prospects. Perfect for high school students and parents supporting educational aspirations. Don't miss this opportunity to make informed decisions and embark on your exciting journey towards higher education.
                            </p>
                            {/* <a href="#register" class="btn btn-primary text-uppercase">
                                Register
                            </a> */}
                            <a href="#discover" class="btn btn-outline-light text-uppercase">
                                Register
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">

                            <div id="slider" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-indicators">
                                    <button
                                        class="active"
                                        type="button"
                                        data-bs-slide-to="0"
                                        data-bs-target="#slider"
                                        aria-current="true"
                                        aria-label="Slide 1"
                                    ></button>
                                    <button
                                        type="button"
                                        data-bs-slide-to="1"
                                        data-bs-target="#slider"
                                        aria-label="Slide 2"
                                    ></button>
                                    <button
                                        type="button"
                                        data-bs-slide-to="2"
                                        data-bs-target="#slider"
                                        aria-label="Slide 3"
                                    ></button>
                                </div>
                                <div class="carousel-inner rounded-5">
                                    <div class="carousel-item active">
                                        <img
                                            src={i1}
                                            alt=""
                                            class="d-block w-100 rounded-5"
                                        />
                                    </div>
                                    <div class="carousel-item">
                                        <img
                                            src={i2}
                                            alt=""
                                            class="d-block w-100 rounded-5"
                                        />
                                    </div>
                                    <div class="carousel-item">
                                        <img
                                            src={i3}
                                            alt=""
                                            class="d-block w-100 rounded-5"
                                        />
                                    </div>
                                </div>

                                <button
                                    class="carousel-control-prev"
                                    type="button"
                                    data-bs-slide="prev"
                                    data-bs-target="#slider"
                                >
                                    <span class="carousel-control-prev-icon"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button
                                    class="carousel-control-next"
                                    type="button"
                                    data-bs-slide="next"
                                    data-bs-target="#slider"
                                >
                                    <span class="carousel-control-next-icon"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

        </div>
    );
};

export default TEvents;